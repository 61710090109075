html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Averta", "Helvetica", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  line-height: 1.4;
  overflow-x: hidden;
}

#root {
  display: flex;
  flex-direction: column;
}

p {
  margin-top: 0;
  max-width: 930px;
}
h1 {
  font-size: 2rem;
  margin-bottom: 15px;
}
h2 {
  font-size: 1.75rem;
}
h3 {  
  font-size: 1.4rem;
}
hr {
  margin: 50px 0;
  border-color: #341B6F;
  border-color: rgba(60, 18, 82, 0.2);
}
body.landing_page {
  background: #341B6F;
  color: #FFF;
}
body.landing_page header {
  background: transparent;
  padding-top: 30px;
}
body.landing_page .soc_logo_large {
  margin-top: -70px;
  margin-bottom: 40px;
}
body.landing_page .soc_logo svg {
  display: none;
}

body.step_1, body.step_2, body.step_3, body.step_4 {
  background: #F9F9F9;
}
body.step_1 footer, body.step_2 footer, body.step_3 footer, body.step_4 footer {
  margin-bottom: 90px;
}

body.results_page .results_link {
  border-bottom: 2px solid white !important;
}
body.account_page .account_link {
  border-bottom: 2px solid white !important;
}

body.about_page .about_link {
  border-bottom: 2px solid white !important;
}

a {
  color: #341B6F;
}
a:hover {
  text-decoration: none;
}
a:focus {
  background: #FFF71A;
  box-shadow: 0 1px #FFF71A, 0 4px #0b0c0c;
  text-decoration: none;
  outline: none;
  border-radius: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media screen and (max-width: 550px){
  .ReactModal__Content {
    padding-bottom: 40px !important;
  }
}